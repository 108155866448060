import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Link } from "react-router-dom";


export default function TheAirbnbs() {
  const scrollToTop = () => window.scrollTo(0, 0);

  const style = {
    color: 'text.primary',
    marginBottom: '18px'
  };
  return (
    <>
      <Typography component="p" sx={style}>
        To celebrate Airbnb's IPO and to help future founders, I thought it might be useful to explain what was special about Airbnb.
      </Typography>
      <Typography component="p" sx={style}>
        What was special about the Airbnbs was how earnest they were. They did nothing half-way, and we could sense this even in the interview. Sometimes after we interviewed a startup we'd be uncertain what to do, and have to talk it over. Other times we'd just look at one another and smile. The Airbnbs' interview was that kind. We didn't even like the idea that much. Nor did users, at that stage; they had no growth. But the founders seemed so full of energy that it was impossible not to like them.
      </Typography>
      <Typography component="p" sx={style}>
        That first impression was not misleading. During the batch our nickname for Brian Chesky was The Tasmanian Devil, because like the  <a target="_blank" href="https://www.youtube.com/watch?v=StG2u5qfFRg&t=147s">cartoon character</a> he seemed a tornado of energy. All three of them were like that. No one ever worked harder during YC than the Airbnbs did. When you talked to the Airbnbs, they took notes. If you suggested an idea to them in office hours, the next time you talked to them they'd not only have implemented it, but also implemented two new ideas they had in the process. "They probably have the best attitude of any startup we've funded" I wrote to Mike Arrington during the batch.
      </Typography>
      <Typography component="p" sx={style}>
        They're still like that. Jessica and I had dinner with Brian in the summer of 2018, just the three of us. By this point the company is ten years old. He took a page of notes about ideas for new things Airbnb could do.
      </Typography>
      <Typography component="p" sx={style}>
        What we didn't realize when we first met Brian and Joe and Nate was that Airbnb was on its last legs. After working on the company for a year and getting no growth, they'd agreed to give it one last shot. They'd try this Y Combinator thing, and if the company still didn't take off, they'd give up.
      </Typography>
      <Typography component="p" sx={style}>
        Any normal person would have given up already. They'd been funding the company with credit cards. They had a <i>binder</i> full of credit cards they'd maxed out. Investors didn't think much of the idea. One investor they met in a cafe walked out in the middle of meeting with them. They thought he was going to the bathroom, but he never came back. "He didn't even finish his smoothie," Brian said. And now, in late 2008, it was the worst recession in decades. The stock market was in free fall and wouldn't hit bottom for another four months.
      </Typography>
      <Typography component="p" sx={style}>
        Why hadn't they given up? This is a useful question to ask. People, like matter, reveal their nature under extreme conditions. One thing that's clear is that they weren't doing this just for the money. As a money-making scheme, this was pretty lousy: a year's work and all they had to show for it was a binder full of maxed-out credit cards. So why were they still working on this startup? Because of the experience they'd had as the first hosts.
      </Typography>
      <Typography component="p" sx={style}>
        When they first tried renting out airbeds on their floor during a design convention, all they were hoping for was to make enough money to pay their rent that month. But something surprising happened: they enjoyed having those first three guests staying with them. And the guests enjoyed it too. Both they and the guests had done it because they were in a sense forced to, and yet they'd all had a great experience. Clearly there was something new here: for hosts, a new way to make money that had literally been right under their noses, and for guests, a new way to travel that was in many ways better than hotels.
      </Typography>
      <Typography component="p" sx={style}>
        That experience was why the Airbnbs didn't give up. They knew they'd discovered something. They'd seen a glimpse of the future, and they couldn't let it go.
      </Typography>
      <Typography component="p" sx={style}>
        They knew that once people tried staying in what is now called "an airbnb," they would also realize that this was the future. But only if they tried it, and they weren't. That was the problem during Y Combinator: to get growth started.
      </Typography>
      <Typography component="p" sx={style}>
        Airbnb's goal during YC was to reach what we call <Link onClick={scrollToTop} to='../ramen-profitable/' style={{ 'text-decoration': 'none' }}>ramen profitability</Link>, which means making enough money that the company can pay the founders' living expenses, if they live on ramen noodles. Ramen profitability is not, obviously, the end goal of any startup, but it's the most important threshold on the way, because this is the point where you're airborne. This is the point where you no longer need investors' permission to continue existing. For the Airbnbs, ramen profitability was $4000 a month: $3500 for rent, and $500 for food. They taped this goal to the mirror in the bathroom of their apartment.
      </Typography>
      <Typography component="p" sx={style}>
        The way to get growth started in something like Airbnb is to focus on the hottest subset of the market. If you can get growth started there, it will spread to the rest. When I asked the Airbnbs where there was most demand, they knew from searches: New York City. So they focused on New York. They went there in person to visit their hosts and help them make their listings more attractive. A big part of that was better pictures. So Joe and Brian rented a professional camera and took pictures of the hosts' places themselves.
      </Typography>
      <Typography component="p" sx={style}>
        This didn't just make the listings better. It also taught them about their hosts. When they came back from their first trip to New York, I asked what they'd noticed about hosts that surprised them, and they said the biggest surprise was how many of the hosts were in the same position they'd been in: they needed this money to pay their rent. This was, remember, the worst recession in decades, and it had hit New York first. It definitely added to the Airbnbs' sense of mission to feel that people needed them.
      </Typography>
      <Typography component="p" sx={style}>
        In late January 2009, about three weeks into Y Combinator, their efforts started to show results, and their numbers crept upward. But it was hard to say for sure whether it was growth or just random fluctuation. By February it was clear that it was real growth. They made $460 in fees in the first week of February, $897 in the second, and $1428 in the third. That was it: they were airborne. Brian sent me an email on February 22 announcing that they were ramen profitable and giving the last three weeks' numbers.
      </Typography>
      <Typography component="p" sx={style}>
        "I assume you know what you've now set yourself up for next week," I responded.
      </Typography>
      <Typography component="p" sx={style}>
        Brian's reply was seven words: "We are not going to slow down."
      </Typography>
    </>
  );
}
