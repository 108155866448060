import * as React from 'react';
import Typography from '@mui/material/Typography';
import { HashLink as InLink } from 'react-router-hash-link';

export default function Template(props) {
  const style = {
    color: 'text.primary',
    marginBottom: '18px'
  };
  const notes = {
    ...style,
    marginTop: '112px'
  }
  const inLinkStyle = {
    textDecoration: 'none', color: 'text.secondary'
  }

  return (
    <>
      {/* <a target="_blank" href="https://paulgraham.com/richnow.html"></a> */}
      {/* <InLink style={inLinkStyle} to="#note-1">[1]</InLink> */}
      <Typography component="p" sx={style}>
        I finally realized today why politics and religion yield such uniquely useless discussions.
      </Typography>
      <Typography component="p" sx={style}>
        As a rule, any mention of religion on an online forum degenerates into a religious argument. Why? Why does this happen with religion and not with Javascript or baking or other topics people talk about on forums?
      </Typography>
      <Typography component="p" sx={style}>
        What's different about religion is that people don't feel they need to have any particular expertise to have opinions about it. All they need is strongly held beliefs, and anyone can have those. No thread about Javascript will grow as fast as one about religion, because people feel they have to be over some threshold of expertise to post comments about that. But on religion everyone's an expert.
      </Typography>
      <Typography component="p" sx={style}>
        Then it struck me: this is the problem with politics too. Politics, like religion, is a topic where there's no threshold of expertise for expressing an opinion. All you need is strong convictions.
      </Typography>
      <Typography component="p" sx={style}>
        Do religion and politics have something in common that explains this similarity? One possible explanation is that they deal with questions that have no definite answers, so there's no back pressure on people's opinions. Since no one can be proven wrong, every opinion is equally valid, and sensing this, everyone lets fly with theirs.
      </Typography>
      <Typography component="p" sx={style}>
        But this isn't true. There are certainly some political questions that have definite answers, like how much a new government policy will cost. But the more precise political questions suffer the same fate as the vaguer ones.
      </Typography>
      <Typography component="p" sx={style}>
        I think what religion and politics have in common is that they become part of people's identity, and people can never have a fruitful argument about something that's part of their identity. By definition they're partisan.
      </Typography>
      <Typography component="p" sx={style}>
        Which topics engage people's identity depends on the people, not the topic. For example, a discussion about a battle that included citizens of one or more of the countries involved would probably degenerate into a political argument. But a discussion today about a battle that took place in the Bronze Age probably wouldn't. No one would know what side to be on. So it's not politics that's the source of the trouble, but identity. When people say a discussion has degenerated into a religious war, what they really mean is that it has started to be driven mostly by people's identities. <InLink style={inLinkStyle} to="#note-1">[1]</InLink>
      </Typography>
      <Typography component="p" sx={style}>
        Because the point at which this happens depends on the people rather than the topic, it's a mistake to conclude that because a question tends to provoke religious wars, it must have no answer. For example, the question of the relative merits of programming languages often degenerates into a religious war, because so many programmers identify as X programmers or Y programmers. This sometimes leads people to conclude the question must be unanswerable—that all languages are equally good. Obviously that's false: anything else people make can be well or badly designed; why should this be uniquely impossible for programming languages? And indeed, you can have a fruitful discussion about the relative merits of programming languages, so long as you exclude people who respond from identity.
      </Typography>
      <Typography component="p" sx={style}>
        More generally, you can have a fruitful discussion about a topic only if it doesn't engage the identities of any of the participants. What makes politics and religion such minefields is that they engage so many people's identities. But you could in principle have a useful conversation about them with some people. And there are other topics that might seem harmless, like the relative merits of Ford and Chevy pickup trucks, that you couldn't safely talk about with others.
      </Typography>
      <Typography component="p" sx={style}>
        The most intriguing thing about this theory, if it's right, is that it explains not merely which kinds of discussions to avoid, but how to have better ideas. If people can't think clearly about anything that has become part of their identity, then all other things being equal, the best plan is to let as few things into your identity as possible. <InLink style={inLinkStyle} to="#note-2">[2]</InLink>
      </Typography>
      <Typography component="p" sx={style}>
        Most people reading this will already be fairly tolerant. But there is a step beyond thinking of yourself as x but tolerating y: not even to consider yourself an x. The more labels you have for yourself, the dumber they make you.
      </Typography>
      <Typography component="p" sx={notes}>
        <b>Notes:</b>
      </Typography>
      <Typography id={'note-1'} component="p" sx={style}>
        [1] When that happens, it tends to happen fast, like a core going critical. The threshold for participating goes down to zero, which brings in more people. And they tend to say incendiary things, which draw more and angrier counterarguments.
      </Typography>
      <Typography id={'note-2'} component="p" sx={style}>
        [2] There may be some things it's a net win to include in your identity. For example, being a scientist. But arguably that is more of a placeholder than an actual label—like putting NMI on a form that asks for your middle initial—because it doesn't commit you to believing anything in particular. A scientist isn't committed to believing in natural selection in the same way a biblical literalist is committed to rejecting it. All he's committed to is following the evidence wherever it leads.
      </Typography>
      <Typography component="p" sx={style}>
        Considering yourself a scientist is equivalent to putting a sign in a cupboard saying "this cupboard must be kept empty." Yes, strictly speaking, you're putting something in the cupboard, but not in the ordinary sense.
      </Typography>
    </>
  );
}
