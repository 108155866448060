import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Link as Mlink } from '@mui/material';

export default function Why(props) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: 'primary.main'
      }}
    >
      <CssBaseline />
      <Container component="main" sx={{ mt: 12, mb: 12, color: 'text.primary' }} maxWidth="md">
        <Typography sx={{ marginBottom: '48px' }} variant="h5" component="h2" gutterBottom>
          {'Why does this website exist?'}
        </Typography>

        <Typography variant="body1" sx={{ marginBottom: '18px' }}>
          From the creator: <i>This site is clean, simple and readable consolidation of Paul Graham's essays. The original essays are challenging to read on a phone or access them offline. This site was initially created for personal use and for friends. Over time, it has gained popularity due to positive feedback.
            It is an attempt to offer a better reading experience and maybe nudge PG to improve their site in retrospect. This site was the outcome of the principle "make something people want".
            Not impersonating or faking Paul G. It simply is a <b>better UX version</b> of Paul G's orginal essay, <b>as is</b>!</i>
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '38px' }}>
          We love you Paul ❤️
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '18px' }}>
          The reader offers 5 different light tone along with simple font customization inspired from Google Read Mode on chrome.
        </Typography>

      </Container>
      <Box
        component="footer"
        sx={{
          py: 2,
          mt: 'auto',
          borderTop: `2px solid ${props?.theme?.palette?.text?.primary} !important`
        }}
      >
        <Container maxWidth="md">
          <Typography variant="body1">
            Paul Graham's orginal essays:
            <a style={{ 'text-decoration': 'none' }} href='https://paulgraham.com/articles.html'>
              <Mlink sx={{ color: 'text.secondary', marginLeft: '8px', }} underline="">
                paulgraham.com/essays
              </Mlink>
            </a>
          </Typography>
        </Container>
      </Box>
    </Box>
  );
}