import * as React from 'react';
import Typography from '@mui/material/Typography';
import { HashLink as InLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';

export default function Template(props) {
  const scrollToTop = () => window.scrollTo(0, 0);

  const style = {
    color: 'text.primary',
    marginBottom: '18px',
  };
  const notes = {
    ...style,
    marginTop: '112px',
  };
  const inLinkStyle = {
    textDecoration: 'none',
    color: 'text.secondary',
  };

  return (
    <>
      {/* https://paulgraham.com/foundermode.html */}
      {/* <a target="_blank" href="https://paulgraham.com/richnow.html"></a> */}
      {/* <InLink style={inLinkStyle} to="#note-1">[1]</InLink> */}
      <Typography component="p" sx={style}>
        At a YC event last week Brian Chesky gave a talk that everyone who was
        there will remember. Most founders I talked to afterward said it was the
        best they'd ever heard. Ron Conway, for the first time in his life,
        forgot to take notes. I'm not going to try to reproduce it here. Instead
        I want to talk about a question it raised.
      </Typography>
      <Typography component="p" sx={style}>
        The theme of Brian's talk was that the conventional wisdom about how to
        run larger companies is mistaken. As Airbnb grew, well-meaning people
        advised him that he had to run the company in a certain way for it to
        scale. Their advice could be optimistically summarized as "hire good
        people and give them room to do their jobs." He followed this advice and
        the results were disastrous. So he had to figure out a better way on his
        own, which he did partly by studying how Steve Jobs ran Apple. So far it
        seems to be working. Airbnb's free cash flow margin is now among the
        best in Silicon Valley.
      </Typography>
      <Typography component="p" sx={style}>
        The audience at this event included a lot of the most successful
        founders we've funded, and one after another said that the same thing
        had happened to them. They'd been given the same advice about how to run
        their companies as they grew, but instead of helping their companies, it
        had damaged them.
      </Typography>
      <Typography component="p" sx={style}>
        Why was everyone telling these founders the wrong thing? That was the
        big mystery to me. And after mulling it over for a bit I figured out the
        answer: what they were being told was how to run a company you hadn't
        founded — how to run a company if you're merely a professional manager.
        But this m.o. is so much less effective that to founders it feels
        broken. There are things founders can do that managers can't, and not
        doing them feels wrong to founders, because it is.
      </Typography>
      <Typography component="p" sx={style}>
        In effect there are two different ways to run a company: founder mode
        and manager mode. Till now most people even in Silicon Valley have
        implicitly assumed that scaling a startup meant switching to manager
        mode. But we can infer the existence of another mode from the dismay of
        founders who've tried it, and the success of their attempts to escape
        from it.
      </Typography>
      <Typography component="p" sx={style}>
        There are as far as I know no books specifically about founder mode.
        Business schools don't know it exists. All we have so far are the
        experiments of individual founders who've been figuring it out for
        themselves. But now that we know what we're looking for, we can search
        for it. I hope in a few years founder mode will be as well understood as
        manager mode. We can already guess at some of the ways it will differ.
      </Typography>
      <Typography component="p" sx={style}>
        The way managers are taught to run companies seems to be like modular
        design in the sense that you treat subtrees of the org chart as black
        boxes. You tell your direct reports what to do, and it's up to them to
        figure out how. But you don't get involved in the details of what they
        do. That would be micromanaging them, which is bad.
      </Typography>
      <Typography component="p" sx={style}>
        Hire good people and give them room to do their jobs. Sounds great when
        it's described that way, doesn't it? Except in practice, judging from
        the report of founder after founder, what this often turns out to mean
        is: hire professional fakers and let them drive the company into the
        ground.
      </Typography>
      <Typography component="p" sx={style}>
        One theme I noticed both in Brian's talk and when talking to founders
        afterward was the idea of being gaslit. Founders feel like they're being
        gaslit from both sides — by the people telling them they have to run
        their companies like managers, and by the people working for them when
        they do. Usually when everyone around you disagrees with you, your
        default assumption should be that you're mistaken. But this is one of
        the rare exceptions. VCs who haven't been founders themselves don't know
        how founders should run companies, and C-level execs, as a class,
        include some of the most skillful liars in the world. [
        <a href="#f1n">1</a>]
      </Typography>
      <Typography component="p" sx={style}>
        Whatever founder mode consists of, it's pretty clear that it's going to
        break the principle that the CEO should engage with the company only via
        his or her direct reports. "Skip-level" meetings will become the norm
        instead of a practice so unusual that there's a name for it. And once
        you abandon that constraint there are a huge number of permutations to
        choose from.
      </Typography>
      <Typography component="p" sx={style}>
        For example, Steve Jobs used to run an annual retreat for what he
        considered the 100 most important people at Apple, and these were not
        the 100 people highest on the org chart. Can you imagine the force of
        will it would take to do this at the average company? And yet imagine
        how useful such a thing could be. It could make a big company feel like
        a startup. Steve presumably wouldn't have kept having these retreats if
        they didn't work. But I've never heard of another company doing this. So
        is it a good idea, or a bad one? We still don't know. That's how little
        we know about founder mode. [<a href="#f2n">2</a>]
      </Typography>
      <Typography component="p" sx={style}>
        Obviously founders can't keep running a 2000 person company the way they
        ran it when it had 20. There's going to have to be some amount of
        delegation. Where the borders of autonomy end up, and how sharp they
        are, will probably vary from company to company. They'll even vary from
        time to time within the same company, as managers earn trust. So founder
        mode will be more complicated than manager mode. But it will also work
        better. We already know that from the examples of individual founders
        groping their way toward it.
      </Typography>
      <Typography component="p" sx={style}>
        Indeed, another prediction I'll make about founder mode is that once we
        figure out what it is, we'll find that a number of individual founders
        were already most of the way there — except that in doing what they did
        they were regarded by many as eccentric or worse. [<a href="#f3n">3</a>]
      </Typography>
      <Typography component="p" sx={style}>
        Curiously enough it's an encouraging thought that we still know so
        little about founder mode. Look at what founders have achieved already,
        and yet they've achieved this against a headwind of bad advice. Imagine
        what they'll do once we can tell them how to run their companies like
        Steve Jobs instead of John Sculley.
      </Typography>
      <Typography component="p" sx={notes}>
        Notes
      </Typography>
      <Typography component="p" sx={style}>
        [<span id={'note-1'}>1</span>] The more diplomatic way of phrasing this
        statement would be to say that experienced C-level execs are often very
        skilled at managing up. And I don't think anyone with knowledge of this
        world would dispute that.
      </Typography>
      <Typography component="p" sx={style}>
        [<span id={'note-2'}>2</span>] If the practice of having such retreats
        became so widespread that even mature companies dominated by politics
        started to do it, we could quantify the senescence of companies by the
        average depth on the org chart of those invited.
      </Typography>
      <Typography component="p" sx={style}>
        [<span id={'note-3'}>3</span>] I also have another less optimistic
        prediction: as soon as the concept of founder mode becomes established,
        people will start misusing it. Founders who are unable to delegate even
        things they should will use founder mode as the excuse. Or managers who
        aren't founders will decide they should try to act like founders. That
        may even work, to some extent, but the results will be messy when it
        doesn't; the modular approach does at least limit the damage a bad CEO
        can do.
      </Typography>
      <Typography component="p" sx={style}>
        Thanks to Brian Chesky, Patrick Collison, Ron Conway, Jessica
        Livingston, Elon Musk, Ryan Petersen, Harj Taggar, and Garry Tan for
        reading drafts of this.
      </Typography>
    </>
  );
}
