import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Link as Mlink } from '@mui/material';
import { CONFIG } from "../config";
import Checkbox from '@mui/material/Checkbox';
import { green } from "@mui/material/colors";

const Reader = (props) => {
  const { slugEssay } = useParams();

  const navigate = useNavigate();
  const essay = CONFIG.find(e => e.slug == slugEssay);
  const slugs = CONFIG.map(e => e.slug);
  const [readList, setReadList] = useState(JSON.parse(localStorage.getItem('read')));

  const toggleRead = (event) => {
    setReadList(p => {
      const filtered = p.filter(item => item !== slugEssay);
      if (event.target.checked) {
        return [...filtered, slugEssay];
      }
      return filtered;
    })
  };

  useEffect(() => {
    const there = slugs.includes(slugEssay);
    if (!there) {
      navigate('/essays');
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('read', JSON.stringify(readList));
  }, [readList]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          backgroundColor: 'primary.main'
        }}
      >
        <CssBaseline />

        <Container component="main" sx={{ mt: 12, mb: 12 }} maxWidth="md">
          <Typography variant="h5" component="h2" sx={{ color: 'text.primary' }} gutterBottom>
            {essay.title}
          </Typography>
          <Typography variant="body1" sx={{ fontSize: '13px', margin: '0 0 24px 0' }}>
            {essay.date}
          </Typography>
          {essay.component(props)}
        </Container>
        <Container component="main" sx={{ mb: 2 }} maxWidth="md">
          <Typography component="p" align="center" sx={{}}>
            <Checkbox
              disableRipple={true}
              checked={readList.indexOf(slugEssay) !== -1}
              onChange={toggleRead}
              sx={{
                color: 'text.primary',
                '&.Mui-checked': {
                  color: 'text.primary',
                },
                marginTop: '-4px',
                marginLeft: -3
              }}
            />
            Mark as Read
          </Typography>
        </Container>
        <Box component="footer" sx={{ py: 2, mt: 'auto', borderTop: `2px solid ${props.theme?.palette.text?.primary} !important` }}>
          <Container maxWidth="md">
            <Typography variant="body1">
              Paul Graham's orginal essay:
              <a target="_blank" style={{ 'text-decoration': 'none' }} href={essay.pgLink}>
                <Mlink sx={{ color: 'text.secondary', marginLeft: '8px', }} underline="">
                  {essay.pgShorthand}
                </Mlink>
              </a>
            </Typography>
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default Reader;
