import React, { useRef, useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Link } from "react-router-dom";
import { Link as Mlink } from '@mui/material';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { CONFIG } from '../config';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function Essays(props) {
  const cutOpacity = 70;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: 'primary.main'
      }}
    >
      <CssBaseline />
      <Container component="main" sx={{ mt: 12, mb: 12, color: 'text.primary' }} maxWidth="md">
        <Typography sx={{ marginBottom: '48px' }} variant="h5" component="h2" gutterBottom>
          Paul Graham's Essays
        </Typography>
        {CONFIG.map(e => (
          <Typography component="p" sx={{
            marginBottom: '16px',
            opacity:
              JSON.parse(localStorage.getItem('read')).indexOf(e.slug) !== -1 ? `${100 - cutOpacity}%` : '100%'
          }}>
            {e.date}
            <Link to={e.slug} style={{ 'text-decoration': 'none' }}>
              <Mlink sx={{ color: 'text.secondary', marginLeft: '18px' }} underline="">
                {e.title}
                <ArrowOutwardIcon sx={{ marginBottom: '-4px', maxHeight: '18px' }} />
              </Mlink>
            </Link>
          </Typography>
        ))}
        {/* <TableContainer elevation={false} sx={{ overflow: 'hidden', backgroundColor: 'primary.main' }} component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="caption table">
            <TableBody>
              {CONFIG.map((e, i) => (
                <TableRow key={i} sx={{ borderBottom: 0 }}>
                  <TableCell style={{ width: 130, padding: '8px 0', borderBottom: 0 }}>
                    <Typography component="p"  >
                      {e.date}
                    </Typography>
                  </TableCell>
                  <TableCell scope="row" align="left" sx={{ padding: '8px 0', borderBottom: 0 }}>
                    <Typography component="p" >
                      <Link to={e.slug} style={{ 'text-decoration': 'none' }}>
                        <Mlink sx={{ color: 'text.secondary', marginLeft: '18px' }} underline="">
                          {e.title}
                          <ArrowOutwardIcon sx={{ marginBottom: '-4px', maxHeight: '18px' }} />
                        </Mlink>
                      </Link>
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer> */}

      </Container>
      <Box
        component="footer"
        sx={{
          py: 2,
          mt: 'auto',
          borderTop: `2px solid ${props?.theme?.palette?.text?.primary} !important`
        }}
      >
        <Container maxWidth="md">
          <Typography variant="body1" component={'p'}>
            Paul Graham's orginal essays:
            <a target="_blank" style={{ 'text-decoration': 'none' }} href='https://paulgraham.com/articles.html'>
              <Mlink sx={{ color: 'text.secondary', marginLeft: '8px', }} underline="">
                paulgraham.com/essays
              </Mlink>
            </a>
            <span style={{ padding: '0 16px' }}>•</span>
            <a style={{ 'text-decoration': 'none' }} href="/essays/why">
              <Mlink sx={{ color: 'text.secondary' }} underline="">
                Why does this website exist?
              </Mlink>
            </a>
          </Typography>
        </Container>
      </Box>
    </Box>
  );
}