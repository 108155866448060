import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import {
  BrowserRouter,
  Routes,
  Switch,
  Route,
  Navigate,
  Link,
  Outlet
} from "react-router-dom";
import Essays from './components/index';
import Reader from './components/Reader';
import Why from './components/why';

import { ThemeProvider, createTheme } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import TextDecreaseIcon from '@mui/icons-material/TextDecrease';
import TextIncreaseIcon from '@mui/icons-material/TextIncrease';
import CancelIcon from '@mui/icons-material/Cancel';
import ReplayIcon from '@mui/icons-material/Replay';
import FormatLineSpacingIcon from '@mui/icons-material/FormatLineSpacing';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import Fab from '@mui/material/Fab';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { THEMES } from './config';

import './App.css';

const getDesignTokens = (mode) => {
  return {
    palette: {
      ...THEMES[mode]
    }
  }
};

const ColorModeContext = createContext({
  toggleColorMode: () => { },
  incrementFontSize: () => { },
  decrementFontSize: () => { },
  lineHeight: () => { },
  letterSpacing: () => { },
  reset: () => { },
});

const defaultConfig = {
  theme: 'strongWhite',
  fontSize: 14,
  lineHeightIndex: 0,
  letterSpacingIndex: 0
}

const read = [];

if (!localStorage.getItem('config')) {
  localStorage.setItem('config', JSON.stringify(defaultConfig));
}

if (!localStorage.getItem('read')) {
  localStorage.setItem('read', JSON.stringify(read));
}

const preConfig = () => JSON.parse(localStorage.getItem('config'));


const App = () => {
  const [open, setOpen] = useState(true);
  const [config, setConfig] = useState(preConfig)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const letterSpaces = [0.5, 1.0, 1.5, 2, 3, 4];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const lineSpaces = [1.5, 2.5, 3.5];

  const theme = useMemo(() => createTheme({
    typography: {
      fontSize: config.fontSize,
      body1: {
        lineHeight: lineSpaces[config.lineHeightIndex % lineSpaces.length],
        letterSpacing: letterSpaces[config.letterSpacingIndex % letterSpaces.length],
      },
      h5: {
        lineHeight: lineSpaces[config.lineHeightIndex % lineSpaces.length],
        letterSpacing: letterSpaces[config.letterSpacingIndex % letterSpaces.length],
      },
      fontFamily: ['Google Sans'].join(","),
    }, ...getDesignTokens(config.theme)
  }), [config, lineSpaces, letterSpaces]);

  const colorMode = useMemo(
    () => ({
      toggleColorMode: (mode) => {
        document.querySelector('meta[name="theme-color"]').setAttribute('content',  THEMES[mode].primary.main);
        setConfig(p => ({
          ...p,
          theme: mode
        }))
      },
      incrementFontSize: () => {
        setConfig(p => ({
          ...p,
          fontSize: p.fontSize < 25 ? p.fontSize + 1 : p.fontSize
        }))
      },
      decrementFontSize: () => {
        setConfig(p => ({
          ...p,
          fontSize: p.fontSize > 7 ? p.fontSize - 1 : p.fontSize
        }))
      },
      lineHeight: () => {
        setConfig(p => ({
          ...p,
          lineHeightIndex: p.lineHeightIndex + 1
        }))
      },
      letterSpacing: () => {
        setConfig(p => ({
          ...p,
          letterSpacingIndex: p.letterSpacingIndex + 1
        }))
      },
      reset: () => {
        setConfig(defaultConfig);
      }
    }),
    [],
  );

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      setOpen(false)
    }
  }, []);

  const toggleOnBodyClick = () =>{
    setOpen((p) => !p);
  }

  useEffect(() => {
    localStorage.setItem('config', JSON.stringify(config));
  }, [config]);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  useEffect(() => {
    document.body.addEventListener('dblclick', toggleOnBodyClick);
    document.querySelector('meta[name="theme-color"]').setAttribute('content',  THEMES[config.theme].primary.main);
  }, []);

  useEffect(() => {
    return () => {
      document.body.removeEventListener('dblclick', toggleOnBodyClick);
    }
  }, [])

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <Fab
          sx={{ position: 'fixed', backgroundColor: 'primary.main', border: `2px solid ${theme?.palette.text?.primary} !important`, width: '36px', height: '36px', right: 0, boxShadow: 'none', margin: '4px', color: 'grey', ':focus': { boxShadow: 'none' } }}
          disableRipple={true}
          disableFocusRipple={true}
          onClick={() => setOpen(true)}
          size="small">
          <VisibilityIcon sx={{ fontSize: '1.25rem', color: 'text.primary' }} />
        </Fab>
        <Drawer
          anchor={'top'}
          open={open}
          variant="persistent"
          sx={{ overflow: 'hidden' }}
        >
          <AppBar position="relative" sx={{ margin: 0, padding: 0, backgroundColor: 'primary.main', borderBottom: `2px solid ${theme?.palette.text?.primary} !important` }}>
            <Toolbar className='no-scroll' sx={{ justifyContent: 'center' }}>
              <div style={{ display: 'flex', gap: '6px' }}>
                <IconButton onClick={() => colorMode.decrementFontSize()} aria-label="delete" size="small" disableElevation={true} disableFocusRipple={false} disableRipple={true}>
                  <TextDecreaseIcon sx={{ fontSize: '1.25rem', color: 'text.primary' }} />
                </IconButton>
                <IconButton onClick={() => colorMode.incrementFontSize()} aria-label="delete" size="small" sx={{ fontSize: '1.125rem' }} disableElevation={true} disableFocusRipple={false} disableRipple={true}>
                  <TextIncreaseIcon sx={{ fontSize: '1.25rem', color: 'text.primary' }} />
                </IconButton>
                <Divider orientation="vertical" variant="middle" flexItem />
                {
                  Object.keys(THEMES).map((e, i) => (
                    <IconButton className={config.theme == e ? 'active-indicator' : ''} onClick={() => colorMode.toggleColorMode(e)} aria-label="delete" size="small" sx={{ fontSize: '1.125rem', '::after': { backgroundColor: `${theme?.palette.text?.primary}` } }} disableElevation={true} disableFocusRipple={false} disableRipple={true}>
                      <div style={{ display: 'flex', alignContent: 'center', width: '18px', border: `2px solid ${theme?.palette.text?.primary}`, height: '18px', borderRadius: '50%', backgroundColor: THEMES[e].text.primary, overflow: 'hidden', }}>
                        <div style={{ width: '50%', outline: 'none', height: '100%', backgroundColor: THEMES[e].primary.main }}>
                        </div>
                      </div>
                    </IconButton>

                  ))
                }
                <Divider orientation="vertical" variant="middle" flexItem />
                <IconButton onClick={() => colorMode.letterSpacing()} aria-label="delete" size="small" sx={{ fontSize: '1.125rem' }} disableElevation={true} disableFocusRipple={false} disableRipple={true}>
                  <FormatColorTextIcon sx={{ fontSize: '1.25rem', color: 'text.primary' }} />
                </IconButton>
                <IconButton onClick={() => colorMode.lineHeight()} aria-label="delete" size="small" sx={{ fontSize: '1.125rem' }} disableElevation={true} disableFocusRipple={false} disableRipple={true}>
                  <FormatLineSpacingIcon sx={{ fontSize: '1.25rem', color: 'text.primary' }} />
                </IconButton>
                <Divider orientation="vertical" variant="middle" flexItem />
                <IconButton onClick={() => colorMode.reset()} aria-label="delete" size="small" sx={{ fontSize: '1.125rem', marginRight: '8px' }} disableElevation={true} disableFocusRipple={false} disableRipple={true}>
                  <ReplayIcon sx={{ fontSize: '1.25rem', color: 'text.primary' }} />
                </IconButton>
              </div>
              <div style={{ position: 'absolute', right: 0 }}>
                <IconButton onClick={() => setOpen(false)} aria-label="delete" size="small" sx={{ fontSize: '1.125rem', marginRight: '8px' }} disableElevation={true} disableFocusRipple={false} disableRipple={true}>
                  <CancelIcon sx={{ fontSize: '1.25rem', color: 'text.primary' }} />
                </IconButton>
              </div>
            </Toolbar>
          </AppBar>
        </Drawer>
        <BrowserRouter>
          <Routes>
            <Route path="/essays">
              <Route index={true} element={<Essays theme={theme} />} />
              <Route index={false} path="why" element={<Why theme={theme} />} />
              <Route index={false} path=":slugEssay" element={<Reader theme={theme} />} />
            </Route>
            <Route path="*" element={<Navigate to="/essays" replace />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </ColorModeContext.Provider >

  );
};

export default App;
