import { lime, purple, lightBlue, blue } from '@mui/material/colors';

// ARTICLES
import TheReddits from "./essays/TheReddits";
import HowToStartGoogle from './essays/HowToStartGoogle';
import TheAirbnbs from './essays/TheAirbnbs';
import Identity from './essays/Identity';
import RamenProfitable from './essays/RamenProfitable';
import GoodAndBadProcastination from './essays/GoodAndBadProcastination';
import Start from './essays/Start';
import PuttingWords from './essays/PuttingWords';
import FounderMode from './essays/FounderMode';


const CONFIG = [
  {
    date: 'September 2024',
    title: 'Founder Mode',
    slug: 'founder-mode',
    component: (props) => <FounderMode {...props} />,
    pgLink: 'https://paulgraham.com/foundermode.html',
    pgShorthand: 'paulgraham.com/foundermode'
  },
  {
    date: 'March 2024',
    title: 'The Reddits',
    slug: 'the-reddits',
    component: (props) => <TheReddits {...props} />,
    pgLink: 'https://paulgraham.com/reddits.html',
    pgShorthand: 'paulgraham.com/reddits'
  },
  {
    date: 'March 2024',
    title: 'How To Start Google',
    slug: 'google',
    component: (props) => <HowToStartGoogle {...props} />,
    pgLink: 'https://paulgraham.com/google.html',
    pgShorthand: 'paulgraham.com/google'
  },
  {
    date: 'February 2022',
    title: 'Putting Ideas into Words',
    slug: 'words',
    component: (props) => <PuttingWords {...props} />,
    pgLink: 'https://paulgraham.com/google.html',
    pgShorthand: 'paulgraham.com/google'
  },
  {
    date: 'December 2020',
    title: 'The Airbnbs',
    slug: 'airbnbs',
    component: (props) => <TheAirbnbs {...props} />,
    pgLink: 'https://paulgraham.com/words.html',
    pgShorthand: 'paulgraham.com/words'
  },
  {
    date: 'July 2009',
    title: 'Ramen Profitable',
    slug: 'ramen-profitable',
    component: (props) => <RamenProfitable {...props} />,
    pgLink: 'https://paulgraham.com/ramenprofitable.html',
    pgShorthand: 'paulgraham.com/ramenprofitable'
  },
  {
    date: 'February 2009',
    title: 'Keep Your Identity Small',
    slug: 'identity',
    component: (props) => <Identity {...props} />,
    pgLink: 'https://paulgraham.com/identity.html',
    pgShorthand: 'paulgraham.com/identity'
  },
  {
    date: 'December 2005',
    title: 'Good and Bad Procrastination',
    slug: 'procrastination',
    component: (props) => <GoodAndBadProcastination {...props} />,
    pgLink: 'https://paulgraham.com/procrastination.html',
    pgShorthand: 'paulgraham.com/procrastination'
  },
  {
    date: 'March 2005',
    title: 'How to Start a Startup',
    slug: 'start',
    component: (props) => <Start {...props} />,
    pgLink: 'https://paulgraham.com/start.html',
    pgShorthand: 'paulgraham.com/start'
  },
]

const THEMES = {
  strongWhite: {
    primary: {
      main: '#ffffff',
    },
    divider: '#000000',
    text: {
      primary: '#000000',
      secondary: blue[700],
    },
  },
  strongBlack: {
    primary: {
      main: '#000000',
    },
    divider: '#ffffff',
    text: {
      primary: '#ffffff',
      secondary: blue[700],
    },
  },
  softBlack: {
    primary: {
      main: '#3c3c3c',
    },
    divider: '#ffffff',
    text: {
      primary: '#ffffff',
      secondary: blue[700],
    },
  },
  softYellow: {
    primary: {
      main: '#feefc3',
    },
    divider: '#180a00',
    text: {
      primary: '#180a00',
      secondary: blue[700],
    },
  },
  softBlue: {
    primary: {
      main: '#d2e3fc',
    },
    divider: '#000000',
    text: {
      primary: '#000000',
      secondary: blue[700],
    },
  },
};

export {
  CONFIG,
  THEMES
}